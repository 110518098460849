;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"88a9d4cf34354b4dc158381122750b522688705c"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { createBeforeSend } from './src/utils/sentry';
import baseOptions from './sentry.base.config';

Sentry.init({
  ...baseOptions,
  beforeSend: createBeforeSend,
  enableTracing: false, // 성능 하락 방지를 위해 비활성화
});
