import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/nextjs';
import { useCountryCode } from '@zep/hooks/useCountryCode';
import { getApplicationSessionId, getBrowserSessionId } from '@zep/utils';
import { getUTMParams, saveUTMParams, zettaClient } from '@zep/utils/analytics';
import { zepLogger } from '@zep/utils/localLogger';
import Bowser from 'bowser';
import { isUndefined, omitBy } from 'lodash-es';
import { useRouter } from 'next/router';

import { zepAnalytics } from '../utils/analytics';

export const useInitAnalytics = () => {
  const { isReady, locale } = useRouter();
  const { countryCode } = useCountryCode();

  useEffect(() => {
    if (isReady) {
      let utmParams = getUTMParams();

      if (Object.values(utmParams).some(value => value)) {
        saveUTMParams(utmParams);
      }
      const { browser, os } = Bowser.parse(window.navigator.userAgent);

      /** 첫 진입시에만 유저 데이터로 저장합니다.(해당 값이 변경/삭제되면 전체 유저 utm 데이터가 오염됩니다) undefined 일 경우 데이터를 넣지않습니다. */
      const userProperties = {
        ...omitBy(utmParams, isUndefined),
        zep_user_agent: window.navigator.userAgent,
        zep_browser_name: browser.name,
        zep_browser_version: browser.version,
        // security 이슈로 Mac OS는 정확한 버전이 지원되지 않습니다.
        zep_os_name: os.name,
        zep_os_version: os.version,
        zep_country_code: countryCode,
        zep_locale: locale,
      };
      zepLogger.setState(userProperties);
      zettaClient.setUserProperties(userProperties);

      zepAnalytics.track('quiz_init', {});
    }
  }, [countryCode, isReady, locale]);

  useEffect(() => {
    Sentry.setTag('zep_application_session_id', getApplicationSessionId());
    Sentry.setTag('zep_browser_session_id', getBrowserSessionId());
  }, []);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`,
      },
    ]);

    ReactGA.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ADS_TRACKING_ID);
    ReactGA.event('conversion', {
      send_to: 'AW-11409990767/ZwXSCJfk9qEZEO_I2sAq',
    });
  }, []);
};
