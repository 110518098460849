import { useMemo } from 'react';
import { NoSSR } from '@zep/components';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import { AuthButton, CreateNewQuizButton } from '@zep/layout/Header/UserManagementButton';
import { useTranslation } from 'next-i18next';
import { GnbButtonInfo, LeftButtons } from './LeftButtons';
import { ExploreButton } from './PageNavigatorButton';
export function GnbHeaderForGoogleClassroom() {
  const {
    t
  } = useTranslation();
  const gnbButtonInfo: Record<'pricing' | 'public', GnbButtonInfo> = useMemo(() => {
    return {
      pricing: {
        alias: '내 퀴즈',
        link: '/me/quiz',
        target: '_self',
        icon: '/assets/icon_magnifying_glass.svg',
        text: t('googleClassroom.pricingTextgnbHeader.pricingText', '내 퀴즈'),
        track: '',
        hideLocales: [],
        eventName: 'GC_gnb_tab_click',
        eventProperties: {
          name: 'myList'
        }
      },
      public: {
        alias: '둘러보기',
        link: '/public',
        target: '_self',
        text: t('header.explore.button'),
        track: '',
        hideLocales: [],
        eventName: 'GC_gnb_tab_click',
        eventProperties: {
          name: 'public'
        }
      }
    };
  }, [t]);
  return <HeaderContainer data-sentry-element="HeaderContainer" data-sentry-component="GnbHeaderForGoogleClassroom" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx">
      <div className="flex items-center gap-[24px]">
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons gnbButtonInfos={gnbButtonInfo} data-sentry-element="LeftButtons" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon data-sentry-element="unknown" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>
        <AuthButton data-sentry-element="AuthButton" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <CreateNewQuizButton type={'normal'} data-sentry-element="CreateNewQuizButton" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />} data-sentry-element="NoSSR" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx">
          <MobileDrawer logo={<div />}
        // MobileDrawer에 내 퀴즈 버튼이 이미 존재하므로 gnbButtonInfo.pricing은 제외합니다.
        gnbButtonInfos={{
          public: gnbButtonInfo.public
        }} footerBottomAccessory={<CreateNewQuizButton type={'drawer'} />} data-sentry-element="MobileDrawer" data-sentry-source-file="GnbHeaderForGoogleClassroom.tsx" />
        </NoSSR>
      </div>
    </HeaderContainer>;
}